@charset "UTF-8";
/* Slider */
.slick {
	margin-bottom: 100px;
}

.slick-loading .slick-list {
	background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
	font-family: "slick";
	src: url("./fonts/slick.eot");
	src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
	font-weight: normal;
	font-style: normal;
}

.slick-slide {
	padding: 0 20px;
	outline: none;
}

/* Arrows */
.slick-prev,
.slick-next {
	position: absolute;
	display: block;
	height: 26px;
	width: 70px;
	line-height: 0px;
	font-size: 0px;
	cursor: pointer;
	background: transparent;
	color: transparent;
	bottom: -70px;
	padding: 0;
	border: none;
	outline: none;
	transition: all 0.5s ease;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
	outline: none;
	background: transparent;
	color: transparent;
	transition: all 0.5s ease;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
	opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
	opacity: 0.25;
}

@media only screen and (min-width: 992px) {
	.slick-prev:before,
	.slick-next:before {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background: url(prev.png);
		position: relative;
		transition: all 0.5s ease;
		opacity: 0.75;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}
	.slick-prev:after,
	.slick-next:after {
		content: '';
		display: block;
		width: 60px;
		height: 60px;
		position: absolute;
		top: -17px;
		left: -33px;
		border: 1px solid #000;
	}
}

.slick-prev {
	left: 38%;
	transform: translateX(-50%);
}

[dir="rtl"] .slick-prev {
	left: auto;
	right: -25px;
}

.slick-prev:before {
	right: 0;
}

[dir="rtl"] .slick-prev:before {
	background: url(next.png);
}

.slick-prev:hover:before {
	right: 20px;
}

.slick-next {
	right: 38%;
	transform: translateX(50%);
}

[dir="rtl"] .slick-next {
	left: -25px;
	right: auto;
}

.slick-next:before {
	background: url(next.png);
	left: 0;
}

[dir="rtl"] .slick-next:before {
	background: url(prev.png);
}

.slick-next:hover:before {
	left: 20px;
}

.slick-next:after {
	right: -33px;
	left: inherit;
}

/* Dots */
.slick-dotted.slick-slider {
	margin-bottom: 30px;
}

.slick-dots {
	list-style: none;
	display: block;
	text-align: center;
	padding: 0;
	margin: 0;
	width: 100%;
}

.slick-dots li {
	position: relative;
	display: inline-block;
	height: 20px;
	width: 20px;
	margin: 0 5px;
	padding: 0;
	cursor: pointer;
}

.slick-dots li button {
	border: 0;
	background: transparent;
	display: block;
	height: 20px;
	width: 20px;
	outline: none;
	line-height: 0px;
	font-size: 0px;
	color: transparent;
	padding: 5px;
	cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
	outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
	opacity: 1;
}

.slick-dots li button:before {
	position: absolute;
	top: 0;
	left: 0;
	content: "•";
	width: 20px;
	height: 20px;
	font-family: "slick";
	font-size: 15px;
	line-height: 20px;
	text-align: center;
	color: black;
	opacity: 0.25;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
	color: black;
	opacity: 0.75;
}
